@import './Base.scss';

footer {
  padding: 2rem 1.1rem 2rem 1.1rem;
}

.footer-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin: auto;
  text-align: center;
  font-size: 1rem;
}

.footer-logo-links {
  font-size: 2rem;
  cursor: pointer;
}

.footer-linkedIn {
  color: #0077b5;
  margin: 0rem 0.5rem 0rem 0rem;
}

.footer-github {
  color: #211f1f;
  margin: 0rem 0.5rem 0rem 0.5rem;
}

.footer-resume {
  color: #f40f02;
  margin: 0rem 1rem 0rem 0.5rem;
}

/* large tablet & laptop styles */
@media screen and (min-width: 992px) {
}

/* desktop styles */
@media screen and (min-width: 1200px) {
  footer {
    padding: 2rem;
  }

  .footer-content {
    width: 50%;
  }
}

.footer-tooltip {
  position: relative;
  display: inline-block;
}

.footer-tooltip .footer-tooltipText {
  visibility: hidden;
  width: 180px;
  height: 100%;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip - right */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -90px; /* Use half of the width (120/2 = 60), to center the tooltip */
}

.footer-tooltip:hover .footer-tooltipText {
  visibility: visible;
}
