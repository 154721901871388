@import './Base.scss';

.card {
  position: relative;
  width: 100%;
  height: 30rem;
  background-size: cover;
  background-color: $white;
  background-clip: content-box;
  border-radius: 20px;
  background-position: center center;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.22) 0px 15px 12px,
    rgba(0, 0, 0, 0.3) 0px 19px 38px;
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.card-inner {
  background-color: rgba(0, 0, 0, 0.9);
  color: $white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  opacity: 0;
  padding: 2rem 1rem 2rem 1rem;
  transition: all 0.4s ease 0s;
}

.card:hover .card-inner {
  opacity: 1;
}

.githubLink {
  position: absolute;
  right: 2rem;
  font-size: 2rem;
  cursor: pointer;
}

.githubLink:hover {
  cursor: pointer;
}

.details {
  overflow-y: scroll;
  height: 87%;
  padding: 1rem;
  margin: 2rem 0 0 0;
}

::-webkit-scrollbar {
  width: 0.3rem;
}

::-webkit-scollbar-thumb {
  background-color: #fff;
  border-radius: 50px;
}

.description {
  font-size: 1rem;
  color: $white;
}

.project-tools {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 0 0;
  word-wrap: break-word;
}

.logo-link {
  font-size: 2rem;
  cursor: pointer;
  margin: 0rem 0.25rem 0rem 0.25rem;
}

.html5 {
  color: #e54b26;
}

.css3 {
  color: #2062af;
}

.sass {
  color: #cf649a;
}

.javascript {
  color: #f7df1e;
}

.react {
  color: #61dbfb;
}

.github {
  cursor: pointer;
}

.typescript {
  width: 1.8rem !important;
  height: 1.8rem !important;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .card {
    display: grid;
    grid-template-areas: 'image content';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    width: 100%;
    height: 100%;
    margin: 0 0 0 0;
  }

  .project-image {
    grid-area: image;
    width: 100%;
  }

  .card-inner {
    grid-area: content;
    background-color: transparent;
    color: $black;
    opacity: 1;
    padding: 2rem;
  }

  .card:hover .card-inner {
    opacity: 1;
  }

  .details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    overflow-y: hidden;
    height: 100%;
    padding: 0;
    margin: 0;
  }

  .project-description {
    font-size: 1.2rem;
    color: $black;
  }

  .project-tools {
    margin: 2rem 0 0 0;
    padding: 0 1rem 0 1rem;
  }

  .logo-link {
    font-size: 2.6rem;
    margin: 0rem 1rem 0rem 1rem;
  }

  .github {
    font-size: 2.4rem;
  }

  .typescript {
    width: 2.3rem !important;
    height: 2.3rem !important;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .card {
    display: grid;
    grid-template-areas: 'image content';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    width: 100%;
    height: 100%;
    margin: 0 0 0 0;
  }

  .project-image {
    grid-area: image;
    width: 100%;
  }

  .card-inner {
    grid-area: content;
    background-color: transparent;
    color: $black;
    opacity: 1;
    padding: 2rem;
  }

  .card:hover .card-inner {
    opacity: 1;
  }

  .details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    overflow-y: hidden;
    height: 100%;
    padding: 0;
    margin: 0;
  }

  .project-description {
    font-size: 1.2rem;
    color: $black;
  }

  .project-tools {
    margin: 2rem 0 0 0;
    padding: 0 1rem 0 1rem;
  }

  .logo-link {
    font-size: 2.6rem;
    margin: 0rem 1rem 0rem 1rem;
  }

  .github {
    font-size: 2.4rem;
  }
}
