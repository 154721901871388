@import './Base.scss';

.history {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: center;
  background-color: $white;
  opacity: 0;
}

.ease {
  opacity: 0;
  animation: easeIn 1s ease-in 0s forwards;
  -webkit-animation: easeIn 1s ease-in 0s forwards;
  -moz-animation: easeIn 1s ease-in 0s forwards;
}

@keyframes easeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.history-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  color: $black;
  font-size: 1.6rem;
  margin: 2rem 2rem 1rem 0rem;
}

.history-title::before {
  content: "";
  position: relative;
  top: 0%;
  display: block;
  height: 2px;
  width: 55%;
  background-color: $main-green;
}

.history-description {
  text-align: start;
  margin: 1rem 2rem 1rem 2rem;
}

.placement {
  text-align: center;
  font-size: 1rem;
  color: $black;
}

.records {
  display: grid;
  grid-template-areas:
    'work'
    'content';
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 100vh;
}

.workplace {
  grid-area: work;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  font-size: 1.2rem;
  margin: 1rem 2rem 1rem 2rem;
}

.tab {
  padding: 0rem 2rem 0rem 0rem;
}

.current {
  border-bottom: 2px solid $main-green;
}

.content {
  grid-area: content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 2rem 0 2rem;
}

.show {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hide {
  display: none;
}

.place {
  color: $main-green;
  font-size: 1.2rem;
  font-weight: bold;
}

.position {
  color: $black;
  font-size: 1rem;
  font-style: italic;
  margin: 1rem 0 0.3rem 0;
}

.date {
  color: $black;
  font-size: 1rem;
  margin: 0.3rem 0 0.5rem 0;
}

.history ul {
  font-size: 1.2rem;
  padding: 0px;
  margin: 2rem 0 0 0;
  list-style: none;
}

.history li {
  position: relative;
  padding-left: 2rem;
  margin-bottom: 0.8rem;
}

.history li::before {
  content: "▹";
  position: absolute;
  left: 0px;
  color: $main-green;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {



}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {



}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

  .history-title {
    margin: 2rem 8rem 2rem 8rem;
  }

  .history-description, .placement {
    display: none;
  }

  .records {
    display: grid;
    grid-template-areas: 'work content';
    grid-template-columns: 10rem 1fr;
    grid-template-rows: 25rem;
    width: 80%;
    margin: 2rem auto;
  }

  .workplace {
    grid-area: work;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    font-size: 1.4rem;
    margin: auto;
    line-height: 4rem;
  }

  .tab {
    text-align: start;
    cursor: pointer;
  }

  .tab:hover {
    color: $white;
    background-color: $main-green;
  }

  .current {
    border-bottom: 2px solid $main-green;
  }

  .content {
    grid-area: content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    margin: auto auto auto 3rem;
  }

  .place {
    color: $main-green;
    font-size: 1.6rem;
    font-weight: bold;
  }

  .position {
    color: $black;
    font-size: 1.2rem;
    font-style: italic;
    margin: 1rem 0 0.3rem 0;
  }

  .date {
    color: $black;
    font-size: 1rem;
    margin: 0.3rem 0 0.5rem 0;
  }

  .history ul {
    padding: 0px;
    margin: 2rem 0 0 0;
    list-style: none;
  }

  .history li {
    position: relative;
    padding-left: 2rem;
    margin-bottom: 0.8rem;
    font-size: 1.2rem;
  }

  .history li::before {
    content: "▹";
    position: absolute;
    top: 0;
    left: 0;
    color: $main-green;
    font-size: 1.6rem;
  }

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  .history-title {
    margin: 2rem 24rem 2rem 24rem;
  }

  .history-description, .placement {
    display: none;
  }

  .records {
    display: grid;
    grid-template-areas: 'work content';
    grid-template-columns: 10rem 1fr;
    grid-template-rows: 25rem;
    width: 50%;
    margin: 2rem auto;
  }

  .workplace {
    grid-area: work;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    font-size: 1.4rem;
    margin: auto;
    line-height: 4rem;
  }

  .tab {
    text-align: start;
    cursor: pointer;
  }

  .tab:hover {
    color: $white;
    background-color: $main-green;
  }

  .current {
    border-bottom: 2px solid $main-green;
  }

  .content {
    grid-area: content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    margin: auto auto auto 3rem;
  }

  .place {
    color: $main-green;
    font-size: 1.6rem;
    font-weight: bold;
  }

  .position {
    color: $black;
    font-size: 1.2rem;
    font-style: italic;
    margin: 1rem 0 0.3rem 0;
  }

  .date {
    color: $black;
    font-size: 1rem;
    margin: 0.3rem 0 0.5rem 0;
  }

  .history ul {
    padding: 0px;
    margin: 2rem 0 0 0;
    list-style: none;
  }

  .history li {
    position: relative;
    padding-left: 2rem;
    margin-bottom: 0.8rem;
    font-size: 1.2rem;
  }

  .history li::before {
    content: "▹";
    position: absolute;
    top: 0;
    left: 0;
    color: $main-green;
    font-size: 1.6rem;
  }

}