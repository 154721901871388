@import './Base.scss';

.side-drawer {
  height: 100%;
  background: $white;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  width: 60%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(100%);
  transition: transform 0.5s ease-out;
}

.side-drawer.open {
  transform: translateX(0);
}

.x-to-close {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 1rem 0 80%;
}

.x-icon-small {
  opacity: 0;
  font-size: 1rem;
}

.x-Icon {
  font-size: 2.4rem;
  color: $main-green;
  transition: all 1.8s linear;
  -webkit-transition: all 1.8s ease-in;
  -moz-transition: all 1.8s ease-in;
}

.rotate {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}

.drawer-nav {
  height: 100%;
}

.drawerLinks {
  position: relative;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@for $i from 1 through 5 {
  .sectionLink#{$i} {
    color: $main-green;
    text-decoration: none;
    font-size: 1.4rem;
    padding: 0 0 3rem 0;
  }
}

.sectionLink {
  color: $main-green;
  text-decoration: none;
  font-size: 1.4rem;
  padding: 0 0 3rem 0;
}

.sectionLink:hover,
.sectionLink:active {
  color: $black;
}

@for $i from 1 to 6 {
  .easeIn#{$i} {
    opacity: 0;
    animation: slide 0.7s ease-in $i * 0.25s forwards;
    -webkit-animation: slide 0.7s ease-in $i * 0.25s forwards;
    -moz-animation: slide 0.7s ease-in $i * 0.25s forwards;
  }
}

@keyframes slide {
  0% {
    opacity: 0;
    transform: translateX(110%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@-webkit-keyframes slide {
  0% {
    opacity: 0;
    transform: translateX(110%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@-moz-keyframes slide {
  0% {
    opacity: 0;
    transform: translateX(110%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.social-media {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: flex-end;
}

.sidebar-tooltip {
  position: relative;
  display: inline-block;
}

.sidebar-tooltip .sidebar-tooltipText {
  visibility: hidden;
  width: 180px;
  height: 100%;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip - left*/
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 105%;
}

.sidebar-tooltip:hover .sidebar-tooltipText {
  visibility: visible;
}
