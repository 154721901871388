@import './Base.scss';

.project {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  background-color: $white;
  opacity: 0;
}

.ease {
  opacity: 0;
  animation: easeIn 0.4s ease-in 0s forwards;
  -webkit-animation: easeIn 0.4s ease-in 0s forwards;
  -moz-animation: easeIn 0.4s ease-in 0s forwards;
}

@keyframes easeIn {
  0% {
    opacity: 0;
    transform: translateX(200%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.project-title {
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  justify-content: space-between;
  text-transform: uppercase;
  color: $black;
  font-size: 1.6rem;
  margin: 2rem 2rem 1rem 0rem;
}

.project-title::before {
  content: '';
  position: relative;
  top: 0%;
  display: block;
  height: 2px;
  width: 50%;
  background-color: $main-green;
}

.slideshow {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  margin: 0;

  .direction {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    margin: 1rem;

    .back-arrow {
      font-size: 2rem;
      color: $main-green;
    }

    .next-arrow {
      font-size: 2rem;
      color: $main-green;
    }

    .back-arrow:hover,
    .next-arrow:hover {
      cursor: pointer;
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {



}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {



}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

  .project {
    height: 100vh;
    min-height: 100%;
  }

  .project-title {
    margin: 2rem 8rem 1rem 8rem;
  }

  .project-title::before {
    margin: 0;
  }

  .slideshow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 80%;
    margin: auto;
    padding: 0;

    .direction {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-content: center;
      text-align: center;
      margin: 1rem 0 1rem 0;

      .project-current-slide {
        font-size: 1.4rem;
        text-align: center;
        margin: auto 0 auto 0;
      }

      .back-arrow {
        font-size: 2rem;
        color: $main-green;
      }

      .next-arrow {
        font-size: 2rem;
        color: $main-green;
      }

      .back-arrow:hover,
      .next-arrow:hover {
        cursor: pointer;
      }
    }
  }

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  .project {
    height: 100vh;
    min-height: 100%;
  }

  .project-title {
    margin: 2rem 24rem 1rem 24rem;
  }

  .project-title::before {
    margin: 0;
  }

  .slideshow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 80%;
    margin: auto;
    padding: 0;

    .direction {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-content: center;
      text-align: center;
      margin: 1rem 0 1rem 0;

      .project-current-slide {
        font-size: 1.4rem;
        text-align: center;
        margin: auto 0 auto 0;
      }

      .back-arrow {
        font-size: 2rem;
        color: $main-green;
      }

      .next-arrow {
        font-size: 2rem;
        color: $main-green;
      }

      .back-arrow:hover,
      .next-arrow:hover {
        cursor: pointer;
      }
    }
  }

}
