@import './Base.scss';

.about {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: center;
  background-color: $white;
  opacity: 0;
}

.ease {
  opacity: 0;
  animation: easeIn 0.4s ease-in 0s forwards;
  -webkit-animation: easeIn 0.4s ease-in 0s forwards;
  -moz-animation: easeIn 0.4s ease-in 0s forwards;
}

@keyframes easeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.about-title {
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  justify-content: space-between;
  text-transform: uppercase;
  color: $black;
  font-size: 1.6rem;
  margin: 2rem 0rem 1rem 2rem;
}

.about-title::after {
  content: '';
  position: relative;
  top: 0%;
  display: block;
  height: 2px;
  width: 50%;
  background-color: $main-green;
}

.about-me {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.about-content {
  padding: 0 2rem 0 2rem;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.me {
  text-align: start;
  margin: 0 0 20px 0;
  font-size: 1rem;
  color: $black;
}

.options {
  width: 100%;
  margin: 2rem 0 0 0;
}

.accordion-set {
  width: 100%;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
}

/* Add a background color to the button if it is clicked on (add the .expanded class with JS), and when you move the mouse over it (hover) */
.expanded,
.accordion:hover {
  background-color: #ccc;
}

.accordion:after {
  content: '\02795'; /* Unicode character for "plus" sign (+) */
  font-size: 0.8rem;
  color: #777;
  float: right;
}

.expanded:after {
  content: '\2796'; /* Unicode character for "minus" sign (-) */
}

/* Style the accordion panel. Note: hidden by default */
.panel {
  padding: 1rem;
  background-color: white;
  display: block;
  overflow: hidden;
}

.description {
  text-align: start;
  color: $black;
  font-size: 1rem;
}

.tech-list {
  display: grid;
  grid-template-columns: repeat(2, minmax(140px, 200px));
  justify-content: center;
  overflow: hidden;
  padding: 0px;
  margin: 20px 0px 0px;
  list-style: none;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .about-title {
    margin: 2rem 8rem 1rem 8rem;
  }

  .about-me {
    width: 80%;
    margin: 0 auto;
  }

  .about-content {
    display: grid;
    grid-template-areas:
      'hello hello tech tech'
      'aboutMe aboutMe techList techList'
      'card card card card';
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 5rem 8rem 25rem;
    padding: 0;
  }

  .me {
    grid-area: aboutMe;
    align-self: center;
    font-size: 1.1rem;
    margin: 0;
  }

  .about-tech {
    grid-area: tech;
    text-align: center;
    align-self: center;
    font-size: 1.1rem;
  }

  .tech-list {
    grid-area: techList;
    text-align: center;
    grid-template-columns: repeat(2, 1fr);
  }

  .options {
    grid-area: card;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1rem;
    margin: 5rem 0 0 0;
  }

  .accordion-set {
    grid-column: span 1;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: transparent;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }

  .accordion {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    cursor: pointer;
    color: $black;
    transition: 0.4s;
    background-color: #eee;
  }

  .accordion:hover {
    background-color: #ccc;
  }

  .expanded {
    opacity: 0;
    transform: rotateY(180deg);
  }

  .accordion:after {
    content: '';
  }

  .expanded:after {
    content: '';
  }

  /* Style the accordion panel. Note: hidden by default */
  .panel {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    padding: 0;
    cursor: pointer;
    border: 1px solid $main-green;
    background-color: $white;
    transform: rotateY(360deg);
    display: flex;
    justify-content: center;
    align-content: center;
  }

  .description {
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1rem;
    margin: auto;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .about-title {
    margin: 2rem 24rem 2rem 24rem;
  }

  .about-me {
    width: 80%;
    margin: 0 auto;
  }

  .about-content {
    display: grid;
    grid-template-areas:
      'hello hello tech tech'
      'aboutMe aboutMe techList techList'
      'card card card card';
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 2rem 8rem 25rem;
    padding: 0;
  }

  .me {
    grid-area: aboutMe;
    align-self: center;
    font-size: 1.2rem;
    margin: 0;
  }

  .about-tech {
    grid-area: tech;
    text-align: center;
    align-self: center;
    font-size: 1.1rem;
  }

  .tech-list {
    grid-area: techList;
    text-align: center;
    grid-template-columns: repeat(2, 1fr);
  }

  .options {
    grid-area: card;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1rem;
    margin: 5rem 0 0 0;
  }

  .accordion-set {
    grid-column: span 1;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: transparent;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }

  .accordion {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    cursor: pointer;
    color: $black;
    transition: 0.4s;
    background-color: #eee;
  }

  .accordion:hover {
    background-color: #ccc;
  }

  .expanded {
    opacity: 0;
    transform: rotateY(180deg);
  }

  .accordion:after {
    content: '';
  }

  .expanded:after {
    content: '';
  }

  /* Style the accordion panel. Note: hidden by default */
  .panel {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    padding: 0;
    cursor: pointer;
    border: 1px solid $main-green;
    background-color: $white;
    transform: rotateY(360deg);
    display: flex;
    justify-content: center;
    align-content: center;
  }

  .description {
    width: 80%;
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.1rem;
    margin: auto;
  }
}
