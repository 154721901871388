html {
  height: 100%;
}

*:focus {
  outline: none;
}

body,
p,
a,
ul,
li {
  margin: 0;
  padding: 0;
  text-decoration: none;
}
li {
  list-style-type: none;
}

/* base styles */
body {
  background: #ffffff;
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
  height: 100%;
}

.portfolio {
  height: 100%;
}

.body {
  width: 100vw;
  overflow-x: hidden;
}

/* large tablet & laptop styles */
@media screen and (min-width: 960px) {

}

/* desktop styles */
@media screen and (min-width: 1200px) {

}
