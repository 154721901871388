/* variables */
$main-green: #589636;
$white: #ffffff;
$tertiary: #4caf50;
$black: #000000;
$supportGray: #5f5f5f;

.slide-title-right {
  opacity: 0;
  animation: slideRight 0.7s ease-in 0.25s forwards;
  -webkit-animation: slideRight 0.7s ease-in 0.25s forwards;
  -moz-animation: slideRight 0.7s ease-in 0.25s forwards;
}

@keyframes slideRight {
  0% {
    opacity: 0;
    transform: translateX(-110%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.slide-title-left {
  opacity: 0;
  animation: slideLeft 0.7s ease-in 0.25s forwards;
  -webkit-animation: slideLeft 0.7s ease-in 0.25s forwards;
  -moz-animation: slideLeft 0.7s ease-in 0.25s forwards;
}

@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(110%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

/* large tablet & laptop styles */
@media screen and (min-width: 960px) {
}

/* desktop styles */
@media screen and (min-width: 1200px) {
}
