@import './Base.scss';

.contact {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  background-color: $white;
  opacity: 0;
}

.ease {
  animation: easeIn 0.4s ease-in 0s forwards;
  -webkit-animation: easeIn 0.4s ease-in 0s forwards;
  -moz-animation: easeIn 0.4s ease-in 0s forwards;
}

@keyframes easeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.contact-title {
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  justify-content: space-between;
  text-transform: uppercase;
  color: $black;
  font-size: 1.4rem;
  margin: 2rem 0rem 1rem 1rem;
}

.contact-title::after {
  content: '';
  position: relative;
  top: 0%;
  display: block;
  height: 2px;
  width: 50%;
  background-color: $main-green;
}

.message {
  text-align: center;
  font-size: 1rem;
  padding: 0 1rem 0 1rem;
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  padding: 1rem;
  height: 100%;
}

.contact input[type='text'],
.contact input[type='email'],
.contact textarea {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  display: block;
  margin: 1rem 0 0 0;
  padding: 0 0 0 1rem;
  width: 100%;
  line-height: 2.6rem;
  font-size: 1.2rem;
  border: 1px solid $main-green;
  border-radius: 10px;
}

.contact input[type='text']:focus,
.contact input[type='email']:focus,
.contact textarea:focus {
  outline: none;
  border-radius: 10px;
  box-shadow: 0px 0px 1px $main-green;
  border: 2px solid lightpink;
}

.submit {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  display: block;
  width: 100%;
  height: auto;
  line-height: 2.6rem;
  background-color: $white;
  color: $main-green;
  border: 1px solid $main-green;
  text-align: center;
  font-size: 1.2rem;
  margin: 1rem 0 0 0;
  border-radius: 10px;
  cursor: pointer;
}

.submit:hover {
  background-color: $main-green;
  color: $white;
  border: 1px solid $white;
}

.contact input[type='text'],
.contact input[type='email'] {
  height: 3rem;
}

.contact textarea {
  height: 10rem;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .contact-title {
    margin: 2rem 8rem 2rem 8rem;
  }

  .message {
    width: 40%;
    font-size: 1.1rem;
    margin: 1rem auto;
  }

  .contact-form {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    padding: 0;
    margin: 1rem auto;
  }

  .contact input[type='text'],
  .contact input[type='email'],
  .contact textarea {
    margin: 2rem 0 0 0;
  }

  .submit {
    margin: 2rem 0 0 0;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .contact-title {
    margin: 2rem 24rem 2rem 24rem;
  }

  .message {
    width: 30%;
    font-size: 1.1rem;
    margin: 1rem auto;
  }

  .contact-form {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    padding: 0;
    margin: 1rem auto;
  }

  .contact input[type='text'],
  .contact input[type='email'],
  .contact textarea {
    margin: 2rem 0 0 0;
  }

  .submit {
    margin: 2rem 0 0 0;
  }
}
