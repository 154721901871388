@import './Base.scss';

.nav-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 4rem;
  z-index: 50;
  background: $white;
}

.nav-grid {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: span 1;
}

.nav-logo {
  grid-column: 1 / 3;
  grid-row: 1;
}

.logo-img {
  width: 60px;
  height: 60px;
}

.nav-link {
  grid-column: 7 / 9;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 0 16px 0 0;
  padding: 0;
  text-decoration: none;
}

.nav-project,
.nav-about,
.nav-history,
.nav-contact {
  display: none;
}

.nav-link > .active {
  color: $main-green;
}

.nav-icon-hamburger {
  font-size: 1.6em;
  color: $main-green;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {


}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {


}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

  .nav-header {
    height: 5rem;
  }

  .logo-img {
    width: 70px;
    height: 70px;
    padding: 0em 0em 0em 1em;
  }

  .logo-img:hover {
    cursor: pointer;
  }

  .nav-link {
    grid-column: 6 / 9;
    justify-content: space-evenly;
    margin: 0;
    padding: 0;
  }

  .nav-project,
  .nav-about,
  .nav-history,
  .nav-contact {
    display: block;
    font-size: 1.2rem;
    color: $black;
  }

  .nav-project:hover,
  .nav-about:hover,
  .nav-history:hover,
  .nav-contact:hover {
    cursor: pointer;
    color: $main-green;
  }

  .nav-icon-hamburger {
    display: none;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  .nav-grid {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: span 1;
  }

  .logo-img {
    width: 70px;
    height: 70px;
    padding: 0em 0em 0em 2em;
  }

  .logo-img:hover {
    cursor: pointer;
  }

  .nav-link {
    grid-column: 8 / -1;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
  }

  .nav-project,
  .nav-about,
  .nav-history,
  .nav-contact {
    display: block;
    font-size: 1.4rem;
    padding: 0em 1em 0em 1em;
    color: $black;
  }

  .nav-project,
  .nav-about,
  .nav-history,
  .nav-contact {
    display: block;
    font-size: 1.4rem;
    padding: 0em 1em 0em 1em;
    color: $black;
  }

  .nav-project:hover,
  .nav-about:hover,
  .nav-history:hover,
  .nav-contact:hover {
    cursor: pointer;
    color: $main-green;
  }

  .nav-icon-hamburger {
    display: none;
  }
}
