@import './Base.scss';

.welcome {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

@keyframes easeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.welcome-content {
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 0em 1rem 0em 1rem;
}

.welcome-hello {
  text-align: start;
  font-size: 1.4rem;
  font-weight: normal;
  color: $black;
  margin: 0 0 1rem 0;
  opacity: 0;
  animation: easeIn 1s ease-in 1s forwards;
  -webkit-animation: easeIn 1s ease-in 1s forwards;
  -moz-animation: easeIn 1s ease-in 1s forwards;
}

.welcome-title {
  text-align: start;
  font-size: 2rem;
  color: $main-green;
  margin: 0;
  opacity: 0;
  margin: 0 0 1rem 0;
  animation: easeIn 1s ease-in 1.2s forwards;
  -webkit-animation: easeIn 1s ease-in 1.2s forwards;
  -moz-animation: easeIn 1s ease-in 1.2s forwards;
}

.welcome-message {
  display: block;
  width: 100%;
  height: 100px;
  opacity: 0;
  text-align: start;
  font-size: 1.1rem;
  color: $black;
  animation: easeIn 1s ease-in 1.4s forwards;
  -webkit-animation: easeIn 1s ease-in 1.4s forwards;
  -moz-animation: easeIn 1s ease-in 1.4s forwards;
}

.logos {
  text-align: start;
  opacity: 0;
  margin: 1.4rem 0 0 0;
  animation: easeIn 1s ease-in 1.6s forwards;
  -webkit-animation: easeIn 1s ease-in 1.6s forwards;
  -moz-animation: easeIn 1s ease-in 1.6s forwards;
}

.logo-link {
  font-size: 2rem;
  cursor: pointer;
}

.linkedIn {
  color: #0077b5;
  margin: 0rem 0.5rem 0rem 0rem;
}

.github {
  color: #211f1f;
  margin: 0rem 0.5rem 0rem 0.5rem;
}

.resume {
  color: #f40f02;
  margin: 0rem 1rem 0rem 0.5rem;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .welcome-content {
    width: 50%;
    height: 100%;
    margin: auto;
    display: grid;
    grid-template-areas: 'icons hello' 'icons title' 'icons message';
    grid-template-columns: 4rem 40rem;
    grid-template-rows: 2rem 3rem 8rem;
    justify-content: center;
    padding: 0 8rem 0 8rem;
  }

  .welcome-hello {
    grid-area: hello;
    font-size: 1.4rem;
    margin: 0;
    padding: 0 0 0 2rem;
    animation-delay: 1.2s;
    -webkit-animation-delay: 1.2s;
    -moz-animation-delay: 1.2s;
  }

  .welcome-title {
    grid-area: title;
    font-size: 3rem;
    margin: 0;
    padding: 0 0 0 2rem;
    animation-delay: 1.4s;
    -webkit-animation-delay: 1.4s;
    -moz-animation-delay: 1.4s;
  }

  .welcome-message {
    grid-area: message;
    display: flex;
    align-items: flex-end;
    width: 82%;
    height: 100%;
    padding: 0 0 0 2rem;
    font-size: 1.4rem;
    animation-delay: 1.6s;
    -webkit-animation-delay: 1.6s;
    -moz-animation-delay: 1.6s;
  }

  .logos {
    grid-area: icons;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    padding: 0 1rem 0 1rem;
    margin: 0;
    animation-delay: 1s;
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
  }

  .logo-link {
    font-size: 5rem;
    cursor: pointer;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .welcome-content {
    width: 50%;
    height: 100%;
    margin: auto;
    display: grid;
    grid-template-areas: 'icons hello' 'icons title' 'icons message';
    grid-template-columns: 4rem 42rem;
    grid-template-rows: 2rem 4rem 10rem;
    justify-content: center;
    padding: 0 25rem 0 25rem;
  }

  .welcome-hello {
    grid-area: hello;
    font-size: 1.4rem;
    margin: 0;
    padding: 0 0 0 2rem;
    animation-delay: 1.2s;
    -webkit-animation-delay: 1.2s;
    -moz-animation-delay: 1.2s;
  }

  .welcome-title {
    grid-area: title;
    font-size: 4rem;
    margin: 0;
    padding: 0 0 0 2rem;
    animation-delay: 1.4s;
    -webkit-animation-delay: 1.4s;
    -moz-animation-delay: 1.4s;
  }

  .welcome-message {
    grid-area: message;
    display: flex;
    align-items: flex-end;
    width: 82%;
    height: 100%;
    padding: 0 0 0 2rem;
    font-size: 1.6rem;
    animation-delay: 1.6s;
    -webkit-animation-delay: 1.6s;
    -moz-animation-delay: 1.6s;
  }

  .logos {
    grid-area: icons;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    padding: 0 1rem 0 1rem;
    margin: 0;
    animation-delay: 1s;
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
  }

  .logo-link {
    font-size: 5rem;
    cursor: pointer;
  }
}

.welcome-tooltip {
  position: relative;
  display: inline-block;
}

.welcome-tooltip .welcome-tooltipText {
  visibility: hidden;
  width: 180px;
  height: 100%;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip - bottom*/
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -70px;
}

.welcome-tooltip:hover .welcome-tooltipText {
  visibility: visible;
}
